import BaseGraph from './base_graph_controller'
import { card, breakDownValue } from '../helpers/tooltip_helper'
import { adoptionRateColorConfigOptions, formatColumnSeries, formatTrendBarSeries, getTickPositions } from '../helpers/copilot_helper'

export default class CopilotUsageChartController extends BaseGraph {
  prepareGraphSchema () {
    const controller = this

    this.compactNumberFormatter = new Intl.NumberFormat('en', { notation: 'compact' })
    const numberFormatter = new Intl.NumberFormat('en')
    const colorConfig = adoptionRateColorConfigOptions(this)
    let series
    let categories = []
    const total = controller.parsedGraphData.total
    if (controller.optionsValue.type === 'spline') {
      categories = controller.parsedGraphData.spline_categories
      const data = controller.parsedGraphData.spline_data
      series = formatTrendBarSeries(data, colorConfig)
    } else if (controller.optionsValue.type === 'column') {
      categories = controller.parsedGraphData.column_categories
      const data = controller.parsedGraphData.column_data
      series = formatColumnSeries(data, colorConfig)
    }

    const maxSettings = this.getYAxisMaxAndTickAmountSettings(total)
    return {
      chart: {
        type: 'column',
        backgroundColor: 'transparent',
        style: {
          fontFamily: 'Inter, Helvetica, Arial, sans-serif',
          overflow: 'visible'
        },
        spacingLeft: 0,
        spacingRight: 0,
        spacingTop: 0,
        spacingBottom: 10
      },
      ...this.baseConfigOptions,
      xAxis: {
        type: 'category',
        categories,
        tickPositions: getTickPositions(categories?.length || 0),
        title: { text: null },
        tickLength: 0,
        labels: {
          enabled: true,
          autoRotation: false,
          useHTML: true,
          formatter: function () {
            if (controller.optionsValue.type === 'column') {
              return `<div class="text-center">
                <div class="text-xs font-medium text-gray-900 dark:text-white">${this.value}</div>
              </div>`
            } else {
              return `<div class="text-center"><div class="text-xs font-medium text-gray-500 dark:text-gray-400">${this.value}</div></div>`
            }
          },
          style: {
            color: controller.darkMode
              ? controller.tailwindColors.gray[400]
              : controller.tailwindColors.gray[500]
          }
        },
        lineWidth: 0
      },
      yAxis: {
        gridLineColor: controller.darkMode ? controller.tailwindColors.gray[700] : controller.tailwindColors.gray[200],
        title: { text: null },
        labels: {
          enabled: true,
          style: {
            color: controller.darkMode
              ? controller.tailwindColors.gray[400]
              : controller.tailwindColors.gray[500]
          },
          formatter: function () {
            if (this.value !== 0 && !total) {
              return '--'
            } else {
              return controller.compactNumberFormatter.format(this.value)
            }
          }
        },
        reversedStacks: false,
        ...maxSettings
      },
      plotOptions: {
        column: controller.optionsValue.type === 'spline' ? this.splinePlotOptions() : this.columnPlotOptions(),
        series: controller.optionsValue.type === 'spline'
          ? {
              dataLabels: {
                enabled: false
              },
              stacking: 'normal',
              cursor: undefined,
              stickyTracking: false
            }
          : {}
      },
      series,
      tooltip: {
        ...this.baseToolTipConfig,
        stickOnContact: true,
        shared: true,
        formatter: function () {
          if (controller.optionsValue.type === 'spline') {
            const breakDownValues = this.points.reverse().map((p) => {
              const color = p.color.pattern ? p.color.pattern.color : p.color
              let unitHtml = ''
              if (controller.parsedGraphData.unit && controller.parsedGraphData.unit_plural) {
                const unit = p.y === 1 ? controller.parsedGraphData.unit : controller.parsedGraphData.unit_plural
                if (unit !== '%') unitHtml += ' '
                unitHtml += `<span class="text-gray-600 dark:text-gray-400">${unit}</span>`
              }
              return breakDownValue({
                name: p.series.name,
                value: `${controller.compactNumberFormatter.format(p.y)}${unitHtml}`,
                style: `background-color: ${color};`,
                type: 'box'
              })
            }).reverse()

            return card({
              date: this.x,
              breakDownValues
            })
          } else {
            let unitHtml = ''
            if (controller.parsedGraphData.unit && controller.parsedGraphData.unit_plural) {
              const unit = this.y === 1 ? controller.parsedGraphData.unit : controller.parsedGraphData.unit_plural
              if (unit !== '%') unitHtml += ' '
              unitHtml += `<span class="text-gray-600 dark:text-gray-400">${unit}</span>`
            }
            return `<div class="shadow-lg p-3 rounded-md bg-white dark:bg-gray-700 min-w-[13rem] z-50">
              <div class="font-medium text-gray-700 dark:text-gray-300">${controller.optionsValue.title}</div>
              <div class="flex justify-between items-center gap-2 mt-3">
                <div class="flex items-center gap-1">
                  <div class="w-3 h-3 rounded-sm" style="background-color:${this.point.color}"></div>
                  <div class="font-medium text-black dark:text-white">${this.point.name}</div>
                </div>
                <div class="font-semibold text-black dark:text-white">${numberFormatter.format(this.y)}${unitHtml}</div>
              </div>
            </div>
            `
          }
        }
      }
    }
  }

  openFlyout (e) {
    this.openMetricDrilldown(e.target.dataset.value)
  }

  splinePlotOptions () {
    return {
      crisp: false,
      pointPadding: 0.1,
      groupPadding: 0,
      borderWidth: 0,
      borderColor: this.darkMode ? this.tailwindColors.gray[800] : this.tailwindColors.white
    }
  }

  columnPlotOptions () {
    const controller = this
    return {
      pointPadding: 0.1,
      groupPadding: 0.1,
      borderRadiusTopLeft: 5,
      borderRadiusTopRight: 5,
      borderColor: 'transparent',
      minPointLength: 5,
      colorByPoint: true,
      maxPointWidth: 150,
      dataLabels: {
        enabled: true,
        crop: false,
        overflow: 'justify',
        formatter: function () {
          let displayValue = (this.y === null || this.y === undefined) ? '--' : controller.compactNumberFormatter.format(this.y)
          if (controller.parsedGraphData.unit && controller.parsedGraphData.unit_plural) {
            const unit = this.y === 1 ? controller.parsedGraphData.unit : controller.parsedGraphData.unit_plural
            if (unit !== '%') displayValue += ' '
            displayValue += unit
          }
          return displayValue
        },
        style: {
          color: this.darkMode ? this.tailwindColors.white : this.tailwindColors.black
        }
      }
    }
  }
}
