import { Controller } from '@hotwired/stimulus'
import { setUrlParams } from '../helpers/url_helper'

export default class extends Controller {
  static targets = ['splineChart', 'columnChart', 'splineChartButton', 'columnChartButton']
  static values = {
    chartType: { type: String, default: 'column' }
  }

  columnChartTargetConnected (chart) {
    chart.classList.toggle('hidden', this.chartTypeValue !== 'column')
  }

  splineChartTargetConnected (chart) {
    chart.classList.toggle('hidden', this.chartTypeValue !== 'spline')
  }

  showSplineChart () {
    this.showChartType('spline')
  }

  showColumnChart () {
    this.showChartType('column')
  }

  showChartType (type) {
    this.chartTypeValue = type
    this.columnChartTargets.forEach(chart => { chart.classList.toggle('hidden', type !== 'column') })
    this.splineChartTargets.forEach(chart => { chart.classList.toggle('hidden', type !== 'spline') })
    setUrlParams('chart_type', type)
  }
}
