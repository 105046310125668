import { Controller } from '@hotwired/stimulus'

export default class UserRoleSelectController extends Controller {
  static targets = ['adminDescription', 'memberDescription']

  setDescription (e) {
    switch (e.target.value) {
      case 'admin':
        this.adminDescriptionTargets.forEach(element => { element.classList.remove('hidden') })
        this.memberDescriptionTargets.forEach(element => { element.classList.add('hidden') })
        break
      case 'member':
        this.adminDescriptionTargets.forEach(element => { element.classList.add('hidden') })
        this.memberDescriptionTargets.forEach(element => { element.classList.remove('hidden') })
        break
    }
  }
}
