export function setUrlParams (paramName, paramValue) {
  const searchParams = new URLSearchParams(window.location.search)
  // add new state to url
  if (paramValue) {
    // the breakdown exists
    searchParams.set(paramName, paramValue)
  } else {
    // None case
    searchParams.delete(paramName)
  }
  const url = [
    window.location.protocol,
    '//',
    window.location.host,
    window.location.pathname,
    '?',
    searchParams
  ].join('')

  // front arrow won't work
  history.replaceState({}, null, url)

  // update the hidden form fields if they exist
  if (document.getElementById(paramName)) document.getElementById(paramName).value = paramValue
}
