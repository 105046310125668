import BaseGraph from './base_graph_controller'
import { card, primaryValue, breakDownValue } from '../helpers/tooltip_helper'
import { getDateTimeStepCount } from '../helpers/axis_helper'

export default class DeploymentFrequencyGraphController extends BaseGraph {
  prepareGraphSchema () {
    const controller = this

    const colorConfig = {
      bg: controller.darkMode ? controller.tailwindColors.blue[800] : controller.tailwindColors.blue[200],
      hover: controller.darkMode ? controller.tailwindColors.blue[900] : controller.tailwindColors.blue[300]
    }
    const seriesData = []
    const emptySeriesData = []
    for (let i = 0; i < controller.parsedGraphData.data.categories.length; i++) {
      let data = controller.parsedGraphData.data.deployments[i]
      if (!data) {
        // add the missing datapoint
        data = {
          x: controller.parsedGraphData.data.categories[i],
          y: 0
        }
      }
      if (!data.y) {
        emptySeriesData.push({
          x: controller.parsedGraphData.data.categories[i],
          y: 0
        })
      }
      if (i === controller.parsedGraphData.data.categories.length - 1) {
        data = { ...data, ...this.getLastDataPointPattern(colorConfig) }
      }
      seriesData.push(data)
    }

    const series = [{
      name: 'Deployments',
      data: seriesData,
      zIndex: 1,
      borderRadiusTopLeft: 5,
      borderRadiusTopRight: 5,
      legendIndex: 0,
      minPointLength: 0,
      yAxis: 0,
      color: controller.darkMode ? controller.tailwindColors.blue[700] : controller.tailwindColors.blue[200],
      states: {
        hover: {
          color: controller.darkMode ? controller.tailwindColors.blue[900] : controller.tailwindColors.blue[300]
        }
      },
      marker: { enabled: false }
    }]

    if (emptySeriesData.length) {
      this.addNoDataSeriesToSeries(series, emptySeriesData)
    }

    const maxDeploymentsRepoTotal = controller.parsedGraphData.data.deployments_repo.reduce((acc, curr) => {
      return Math.max(acc, curr.y)
    }, 0)
    const deploymentsRepoTotalMaxSettings = this.getYAxisMaxAndTickAmountSettings(maxDeploymentsRepoTotal)
    const deploymentsTotalMaxSettings = this.getYAxisMaxAndTickAmountSettings(controller.parsedGraphData.max)

    // add the spline and the dashed line averages
    series.push(
      {
        name: 'Deployment / repo',
        data: controller.parsedGraphData.data.deployments_repo,
        color: controller.darkMode ? controller.tailwindColors.blue[300] : controller.tailwindColors.blue[500],
        lineColor: controller.darkMode ? controller.tailwindColors.blue[300] : controller.tailwindColors.blue[500],
        zIndex: 2,
        type: 'spline',
        yAxis: 1,
        marker: { enabled: false }
      }
    )
    series.push(
      {
        name: 'Average',
        data: controller.parsedGraphData.data.avg_deployments_repo,
        color: controller.darkMode ? controller.tailwindColors.blue[200] : controller.tailwindColors.blue[900],
        lineColor: controller.darkMode ? controller.tailwindColors.blue[200] : controller.tailwindColors.blue[900],
        zIndex: 2,
        type: 'line',
        dashStyle: 'ShortDash',
        yAxis: 1,
        marker: { enabled: false }
      }
    )

    return {
      chart: {
        type: 'column',
        backgroundColor: 'transparent',
        style: {
          fontFamily: 'Inter, Helvetica, Arial, sans-serif',
          overflow: 'visible'
        },
        spacingTop: 10,
        spacingRight: 0,
        spacingBottom: 0,
        spacingLeft: 0
      },
      ...this.baseConfigOptions,
      xAxis: {
        type: 'datetime',
        title: { text: null },
        tickPositions: controller.parsedGraphData.data.categories,
        tickLength: 0,
        labels: {
          step: getDateTimeStepCount(controller.parsedGraphData.data.categories?.length || 0),
          formatter: function () {
            return Highcharts.dateFormat('%b %e', this.value)
          },
          style: {
            color: controller.darkMode
              ? controller.tailwindColors.gray[400]
              : controller.tailwindColors.gray[500]
          }
        },
        gridLineColor: 'transparent',
        lineColor: controller.darkMode
          ? controller.tailwindColors.gray[600]
          : controller.tailwindColors.gray[300],
        maxPadding: 0
      },
      yAxis: [
        {
          gridLineColor: controller.darkMode ? controller.tailwindColors.gray[600] : controller.tailwindColors.gray[200],
          title: { text: null },
          labels: {
            enabled: true,
            style: {
              color: controller.darkMode
                ? controller.tailwindColors.gray[400]
                : controller.tailwindColors.gray[500]
            },
            formatter: function () {
              if (this.value !== 0 && !controller.parsedGraphData.max) {
                return '--'
              } else {
                return this.value
              }
            }
          },
          reversedStacks: false,
          ...deploymentsTotalMaxSettings
        },
        {
          gridLineWidth: 0,
          title: { text: null },
          labels: {
            enabled: true,
            style: {
              color: controller.darkMode
                ? controller.tailwindColors.gray[400]
                : controller.tailwindColors.gray[500]
            },
            formatter: function () {
              if (this.value !== 0 && maxDeploymentsRepoTotal === 0) {
                return '--'
              } else {
                return this.value
              }
            }
          },
          reversedStacks: false,
          opposite: true,
          ...deploymentsRepoTotalMaxSettings
        }
      ],
      plotOptions: {
        column: {
          crisp: false,
          pointPadding: 0.1,
          groupPadding: 0,
          borderWidth: 1,
          borderColor: 'transparent',
          borderRadiusTopLeft: 5,
          borderRadiusTopRight: 5
        },
        series: {
          dataLabels: {
            enabled: false
          },
          stacking: 'normal',
          cursor: 'pointer',
          stickyTracking: false,
          point: {
            events: {
              click: function () {
                if (controller.optionsValue.metrics_url) {
                  controller.openDeploymentDrilldown(new Date(this.x).toISOString().split('T')[0])
                }
              }
            }
          }
        }
      },
      series,
      tooltip: {
        ...this.baseToolTipConfig,
        stickOnContact: true,
        shared: true,
        formatter: function () {
          const breakDownValues = this.points.map((p, i) => {
            if (p.series.name === 'No data' || p.series.name === 'Deployments') {
              return null
            } else {
              const color = p.color.pattern ? p.color.pattern.color : p.color
              let type = 'box'
              let style
              switch (p.series.name) {
                case 'Average':
                  type = 'dash'
                  style = `color: ${color};`
                  break
                case 'Deployment / repo':
                  type = 'line'
                  style = `background-color: ${color};`
                  break
              }
              return breakDownValue({
                name: p.series.name,
                value: p.y,
                style,
                type
              })
            }
          })

          const endDate = controller.getEndDate(
            this.x, controller.optionsValue.date_granularity
          )
          const date = `${Highcharts.dateFormat('%B %e', this.x)}` + ' - ' + `${Highcharts.dateFormat('%B %e', endDate)}`
          return card({
            date: date,
            incomplete: this.points[0].point.index === (controller.parsedGraphData.data.categories.length - 1),
            footer: controller.optionsValue.metrics_url && `<button data-value="${this.x}" data-action="click->deployment-frequency-graph#openFlyout" class="btn-tertiary text-xs mt-2 w-full">View deployments</button>`,
            primaryValues: [
              primaryValue({ name: 'Successful deployments', value: this.y })
            ],
            breakDownValues
          })
        }
      }
    }
  }
}
