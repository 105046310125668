import { Controller } from '@hotwired/stimulus'

export default class ClipboardController extends Controller {
  static targets = ['copy', 'input']
  static values = { success: String }

  copy () {
    navigator.clipboard.writeText(this.copyTarget.dataset.clipboardValue).then(() => {
      this.copyTarget.innerHTML = this.hasSuccessValue ? this.successValue : 'Copied!'
    })
  }
}
