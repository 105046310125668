import BaseGraph from './base_graph_controller'
import { card, breakDownValue } from '../helpers/tooltip_helper'
import { adoptionRateColorConfigOptions, formatColumnSeries, formatSplineSeries, getTickPositions } from '../helpers/copilot_helper'

export default class CopilotAdoptionRateChartController extends BaseGraph {
  prepareGraphSchema () {
    const controller = this

    const colorConfig = adoptionRateColorConfigOptions(this)

    let data = []
    let series
    let categories = []
    let total = 0
    if (controller.optionsValue.type === 'spline') {
      categories = controller.parsedGraphData.spline_categories
      data = controller.parsedGraphData.spline_data
      data.forEach((item) => {
        total += item.data.reduce((acc, value) => acc + value, 0)
      })
      series = formatSplineSeries(data, categories, colorConfig)
    } else if (controller.optionsValue.type === 'column') {
      categories = controller.parsedGraphData.column_categories
      data = controller.parsedGraphData.column_data
      total = data.reduce((acc, item) => acc + item.y, 0)
      series = formatColumnSeries(data, colorConfig)
    }

    const maxSettings = this.getYAxisMaxAndTickAmountSettings(total)
    maxSettings.maxPadding = undefined
    return {
      chart: {
        type: 'column',
        backgroundColor: 'transparent',
        style: {
          fontFamily: 'Inter, Helvetica, Arial, sans-serif',
          overflow: 'visible'
        },
        spacingLeft: 0,
        spacingRight: 0,
        spacingTop: 0,
        spacingBottom: 10
      },
      ...this.baseConfigOptions,
      xAxis: {
        type: 'category',
        categories,
        tickPositions: getTickPositions(categories?.length || 0),
        title: { text: null },
        tickLength: 0,
        labels: {
          enabled: true,
          autoRotation: false,
          useHTML: true,
          formatter: function () {
            if (controller.optionsValue.type === 'column') {
              return `<div class="text-center">
                <div class="text-xs font-medium text-gray-900 dark:text-white">${this.value}</div>
                <div class="text-xs text-gray-500 dark:text-gray-400">${controller.parsedGraphData.column_chart_date}</div>
              </div>`
            } else {
              return `<div class="text-center"><div class="text-xs font-medium text-gray-500 dark:text-gray-400">${this.value}</div></div>`
            }
          },
          style: {
            color: controller.darkMode
              ? controller.tailwindColors.gray[400]
              : controller.tailwindColors.gray[500]
          }
        },
        lineWidth: 0
      },
      yAxis: {
        gridLineColor: controller.darkMode ? controller.tailwindColors.gray[700] : controller.tailwindColors.gray[200],
        title: { text: null },
        labels: {
          enabled: true,
          style: {
            color: controller.darkMode
              ? controller.tailwindColors.gray[400]
              : controller.tailwindColors.gray[500]
          },
          formatter: function () {
            if (this.value !== 0 && !total) {
              return '--'
            } else {
              return this.value
            }
          }
        },
        reversedStacks: false,
        ...maxSettings
      },
      plotOptions: {
        column: controller.optionsValue.type === 'spline' ? this.splinePlotOptions() : this.columnPlotOptions(),
        series: controller.optionsValue.metrics_url && controller.optionsValue.type === 'spline'
          ? {
              dataLabels: {
                enabled: false
              },
              stacking: 'normal',
              cursor: controller.optionsValue.metrics_url ? 'pointer' : undefined,
              stickyTracking: false,
              point: {
                events: {
                  click: function () {
                    if (controller.optionsValue.metrics_url) {
                      controller.openMetricDrilldown(controller.parsedGraphData.date_map[this.category], this.y)
                    }
                  }
                }
              }
            }
          : {}
      },
      series,
      tooltip: {
        ...this.baseToolTipConfig,
        stickOnContact: true,
        shared: true,
        formatter: function () {
          if (controller.optionsValue.type === 'spline') {
            const breakDownValues = this.points.reverse().map((p) => {
              const color = p.color.pattern ? p.color.pattern.color : p.color
              return breakDownValue({
                name: p.series.name,
                value: `${p.y} <span class="text-gray-600 dark:text-gray-400">developers</span>`,
                style: `background-color: ${color};`,
                type: 'box'
              })
            }).reverse()

            return card({
              date: controller.parsedGraphData.date_map[this.x],
              breakDownValues,
              footer: controller.optionsValue.metrics_url && `<button data-value="${controller.parsedGraphData.date_map[this.x]}" data-action="click->copilot-adoption-rate-chart#openFlyout" class="btn-tertiary text-xs mt-2 w-full">View developers</button>`
            })
          } else {
            return `<div class="shadow-lg p-3 rounded-md bg-white dark:bg-gray-700 min-w-[13rem] z-50">
              <div class="font-medium text-gray-700 dark:text-gray-300">${controller.optionsValue.title}</div>
              <div class="flex justify-between items-center gap-2 mt-3">
                <div class="flex items-center gap-1">
                  <div class="w-3 h-3 rounded-sm" style="background-color:${this.point.color}"></div>
                  <div class="font-medium text-black dark:text-white">${this.point.name}</div>
                </div>
                <div class="font-semibold text-black dark:text-white">${this.y} <span class="text-gray-600 dark:text-gray-400">developers</span></div>
              </div>
            </div>
            `
          }
        }
      }
    }
  }

  openFlyout (e) {
    this.openMetricDrilldown(e.target.dataset.value)
  }

  splinePlotOptions () {
    return {
      crisp: false,
      pointPadding: 0.1,
      groupPadding: 0,
      borderWidth: 0,
      borderColor: this.darkMode ? this.tailwindColors.gray[800] : this.tailwindColors.white
    }
  }

  columnPlotOptions () {
    return {
      pointPadding: 0.1,
      groupPadding: 0.1,
      borderRadiusTopLeft: 5,
      borderRadiusTopRight: 5,
      borderColor: 'transparent',
      minPointLength: 5,
      colorByPoint: true,
      maxPointWidth: 150,
      dataLabels: {
        enabled: true,
        crop: false,
        overflow: 'justify',
        formatter: function () {
          if (!this.y) {
            return '-- developers'
          } else if (this.y === 1) {
            return '1 developer'
          }
          return `${this.y} developers`
        },
        style: {
          color: this.darkMode ? this.tailwindColors.white : this.tailwindColors.black
        }
      }
    }
  }
}
