import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="upgrade-to-business-plan-modal"
export default class extends Controller {
  static targets = ['annual', 'monthly']
  showAnnualPlan () {
    this.annualTarget.classList.remove('hidden')
    this.monthlyTarget.classList.add('hidden')
  }

  showMonthlyPlan () {
    this.annualTarget.classList.add('hidden')
    this.monthlyTarget.classList.remove('hidden')
  }
}
